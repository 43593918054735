import { javascript } from '@api/index'
import { StoreMode, useHydration, useNestedStore, useNestedStores } from '@stores/utils'
import { defineStore, getActivePinia } from 'pinia'
import { ref } from 'vue'
import { createTemplateVersionStore } from './templateVersion.store'

export type TemplateStoreType = ReturnType<typeof useTemplateStore>
export const allTemplateStores = ref<Array<TemplateStoreType>>([])

export type TemplateDataType = Backend.Models.Template & {
  template_version_ids: number[]
}

export const createTemplateStore = (uniqueId: string | number) => {
  const templateStore = defineStore(
    'template-store-' + String(uniqueId),
    () => {
      const hydration = useHydration<TemplateDataType>(
        javascript.template
      )
      const hydrateById = (
        id: number,
        fields: Array<keyof TemplateDataType> = []
      ) => {
        return hydration.hydrate({ id }, fields)
      }
      allTemplateStores.value.push(
        templateStore as unknown as TemplateStoreType
      )

      const $default = () => {
        hydration.hydratedData.value = {
          id: null,
          user_id: null,
          current_version_id: null,
          template_version_ids: []
        } as unknown as TemplateDataType
        hydration.storeMode.value = StoreMode.NewData
        hydration.hydrated.value = true
      }

      return {
        ...hydration,
        hydrateById,
        id: hydration.hydratedComputed('id'),
        userId: hydration.hydratedComputed('user_id'),
        currentVersionId: hydration.hydratedComputed('current_version_id'),
        ...useNestedStore(hydration, 'currentVersion', 'current_version_id', createTemplateVersionStore),
        ...useNestedStores(hydration, 'templateVersions', 'template_version_ids', createTemplateVersionStore),
        $default
      }
    }
  )
  return templateStore
}

export const useTemplateStore = createTemplateStore('current')

export const getCurrentTemplateId = (): number => {
  // Check if templateStore is hydrated
  const templateStore = useTemplateStore(getActivePinia())
  if (
    templateStore.hydrated &&
    templateStore.hydratedData &&
    templateStore.hydratedData.id != null
  )
    return templateStore.hydratedData.id

  // Otherwise fetch from hidden field
  const templateId = document.getElementById('template-id')
  if (templateId == null) throw new Error('#template-id not found')
  if (!templateId.hasAttribute('value'))
    throw new Error('Value attribute not found on #template-id')
  return +templateId.getAttribute('value')!
}

export const getCurrentTemplateVersionId = (): number => {
  // Check if templateStore is hydrated
  const templateStore = useTemplateStore(getActivePinia())
  if (
    templateStore.hydrated &&
    templateStore.hydratedData &&
    templateStore.hydratedData.current_version_id != null
  )
    return templateStore.hydratedData.current_version_id

  // Otherwise fetch from hidden field
  const templateVersionId = document.getElementById('template-version-id')
  if (templateVersionId == null)
    throw new Error('#template-version-id not found')
  if (!templateVersionId.hasAttribute('value'))
    throw new Error('Value attribute not found on #template-version-id')
  return +templateVersionId.getAttribute('value')!
}
